import * as React from "react"
import { useContext } from "react";
import styled from "@emotion/styled";
import theme, { Size, Type, ThemeContext, Weight } from "utils/theme";

interface StyledPProps {
  color: string;
  lineHeight: number;
  size: number;
  weight: number;
}

const StyledP = styled.p<StyledPProps>`
  color: ${({ color }) => color};
  line-height: ${({ lineHeight }) => lineHeight}px;
  font-size: ${({ size }) => size}px;
  font-weight: ${({ weight }) => weight};
  margin: 0;
`;

interface PProps {
  fontSize?: Size;
  fontWeight?: Weight;
  type?: Type;
}

const P: React.FC<PProps> = ({ children, fontSize = "base", type = "primary", fontWeight = 400 }) => {
  const themeContext = useContext(ThemeContext);
  const { font } = theme[themeContext];
  const { lineHeight, size } = theme.font[fontSize];
  return (
    <StyledP color={font[type]} lineHeight={lineHeight} size={size} weight={fontWeight}>{children}</StyledP>
  );
};

export default P;
