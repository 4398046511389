import * as React from "react"
import { useContext } from "react";
import styled from "@emotion/styled";
import theme, { Type, ThemeContext } from "utils/theme";

interface StyledH1Props {
  color: string;
}

const StyledH1 = styled.h1<StyledH1Props>`
  color: ${({ color }) => color};
  font-size: 64px;
  font-weight: 900;
  line-height: 78px;
  margin: 0 0 8px;
`;

interface H1Props {
  type: Type;
}

const H1: React.FC<H1Props> = ({ children, type = "primary" }) => {
  const themeContext = useContext(ThemeContext);
  const { font } = theme[themeContext];
  return (
    <StyledH1 color={font[type]}>{children}</StyledH1>
  );
};

export default H1;
