import * as React from "react"
import { useContext } from "react";
import styled from "@emotion/styled";
import theme, { ThemeContext } from "utils/theme";

interface SectionContainerProps {
  background: string;
}
const SectionContainer = styled.section<SectionContainerProps>`
  background: ${({ background }) => background};
  padding: 32px;
`;

interface SectionProps {
  className?: string;
}

const Section: React.FC<SectionProps> = ({ children, className }) => {
  const themeContext = useContext(ThemeContext);
  const { background } = theme[themeContext];

  return (
    <SectionContainer background={background} className={className}>
      {children}
    </SectionContainer>
  );
};

export default Section;
