import * as React from "react"
import styled from "@emotion/styled";


import H1 from "components/H1";
import P from "components/P";
import Link from "components/Link";
import Section from "components/Section";
import Beta from "images/beta.svg";
import { ThemeContext } from "utils/theme";

const HeroContainer = styled(Section)`
  height: 928px;
  padding-top: 309px;
  position: relative;
  text-align: right;
`;

const StyledLink = styled(Link)`
  position: relative;
  top: 216px;
`;

const BetaContainer = styled.div`
  bottom: 0;
  left: 148px;
  position: absolute;
  height: 740px;
`;

const Hero: React.FC = () => {
  return (
    <ThemeContext.Provider value="dark">
      <HeroContainer>
        <H1 type="primary">We Build and Launch Ideas</H1>
        <P fontSize="large" type="alternate">Small team. Big ideas. We write software. We build apps.</P>
        <StyledLink fontSize="large" fontWeight={800} to="#">Let's work together</StyledLink>
        <BetaContainer>
          <Beta />
        </BetaContainer>
      </HeroContainer>
    </ThemeContext.Provider>
  );
};

export default Hero;
